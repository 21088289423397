<template>
  <el-card class="balance-card" shadow="never">
    <div slot="header" class="clearfix">
      <i class="fas fa-donate"></i>
      {{ $t("toefl.Balance") }}
    </div>
    <div class="body">
      <div class="balance text-center">
        <h6 class="info-tip">{{ $t("toefl.Balance") }}</h6>
        <h3 class="my-balance">{{ point }}</h3>
        <span class="balance-name">{{ $t("toefl.T-Coins") }}</span>
      </div>
      <div class="addBalance text-center" v-if="showLink">
        <router-link :to="{ name: 'ToeflProfileBalance' }">
          <el-button type="success">
            {{ $t("toefl.Get more T-Coins") }}
          </el-button>
        </router-link>
      </div>
    </div>
  </el-card>
</template>

<script>
export default {
  components: {},

  mixins: [],

  props: ["point", "showLink"],
  data() {
    return {};
  },
  computed: {},
  watch: {},

  mounted() {},

  methods: {}
};
</script>

<style scoped>
.balance-card {
  height: 100%;
}
.info-tip {
  margin-top: 15px;
  margin-bottom: 13px;
  font-size: 14px;
  color: #99a2aa;
  letter-spacing: 0;
}
.my-balance {
  display: inline-block;
  font-size: 50px;
  color: var(--themeColor);
}
.addBalance {
  margin-top: 10px;
}
.balance-name {
  display: inline-block;
  font-size: 16px;
  margin-left: 4px;
  color: var(--themeColor);
}

@media screen and (max-width: 768px) {
  .info-tip {
    display: none;
  }
  .balance,
  .addBalance {
    display: inline-block;
    margin-left: 0;
  }
  ::v-deep .el-button,
  .addBalance {
    margin: 0;
  }
  .addBalance {
    position: relative;
    /* height: 68px; */
    margin-left: 10px;
  }
  ::v-deep .el-button {
    position: absolute;
    bottom: -10px;
  }
}
</style>
